.button {
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #FFFFFF;
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
}

.button:hover {
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.1);
}

.extraSmall {
    width: 46px;
    height: 40px;
}

.small {
    width: 66px;
    height: 40px;
}

.medium {
    width: 96px;
    height: 54px;
    padding: 16px 20px;
    font-size: 15px;
    line-height: 22px;
}

.large {
    width: 112px;
    height: 54px;
    font-size: 15px;
    padding: 16px 20px;
    line-height: 22px;
    background: #4CC18B;
    border: none;
}
.large:hover {
    background: #4CC18B;
}

.fullwidth {
    width: 100%;
}
.unsetWidth {
    width: unset;
}
.unsetHeight {
    height: unset;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.row.ltr {
    flex-direction: row;
}

.row.rtl {
    flex-direction: row-reverse;
}

.icon svg {
    margin-left: 8px;
    display: block;
}

.left {
    border-radius: 4px 0 0 4px;
}
.right {
    border-radius: 0 4px 4px 0;
}
.all {
    border-radius: 4px 4px 4px 4px;
}

.selected {
    background: #FFFFFF;
    color: #0D5068;
    transition: all 0.2s ease-out;
}
.selected:hover {
    background: #FFFFFF;
    color: #0D5068;
}