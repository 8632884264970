html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
@font-face {
  font-family: 'LoewNext';
  src: url(/static/media/loew_next_arabic_medium.8627e984.woff) format('woff'), url(/static/media/loew_next_arabic_medium.ea7ed2c2.woff2) format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'LoewNext';
  src: url(/static/media/loew_next_arabic_bold.13c7bc23.woff) format('woff'), url(/static/media/loew_next_arabic_bold.55ea753a.woff2) format('woff2');
  font-weight: 700;
}

html, body, #root {
  min-height: 100vh;
  font-weight: 400;
}

.arial {
  & * {
    font-family: Arial, sans-serif;
  }
}

.loew {
  & * {
    font-family: LoewNext, sans-serif;
  }
}
.App_app__2fOEC {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}

.App_app__2fOEC::before {
  content: '';
  background: url(/static/media/bottomElipse.48b670ee.svg) no-repeat;
  background-size: 100% 100%;
  width: 355px;
  height: 355px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.App_app__2fOEC::after {
  content: '';
  background: url(/static/media/1280.fae3590b.svg) no-repeat;
  background-size: 100% 100%;
  width: 638px;
  height: 542px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

@media screen and (max-width: 1100px){
  .App_app__2fOEC::before {
    left: -140px;
  }
  .App_app__2fOEC::after{
    background: url(/static/media/768.6487f4c1.svg) no-repeat;
    background-size: 100% 100%;
    width: 302px;
    height: 542px;
  }
}

@media screen and (max-width: 767px) {
  .App_app__2fOEC::before {
    width: 227px;
    height: 227px;
    left: -146px;
  }
  .App_app__2fOEC::after{
    background: url(/static/media/375.c48404b1.svg) no-repeat;
    background-size: 100% 100%;
    width: 228px;
    height: 418px;
  }
}

@media screen and (max-width: 374px){
  .App_app__2fOEC::after{
    right: -90px;
  }
}

.Layout_container__3h6vp {
    padding: 0 20px;
}

.Layout_ltr__Br5a0 {
    direction: ltr;
}

.Layout_rtl__2gGw7 {
    direction: rtl;
}

@media screen and (min-width: 575.98px){
    .Layout_container__3h6vp {
        max-width: 540px;
        margin: auto;
        padding: 0;
    }
}

@media screen and (min-width: 767.98px){
    .Layout_container__3h6vp {
        max-width: 720px;
        padding: 0 24px
    }
}

@media screen and (min-width: 991.98px) {
    .Layout_container__3h6vp {
        max-width: 960px;
    }
}

@media screen and (min-width: 1199.98px){
    .Layout_container__3h6vp {
        max-width: 1140px;

    }
}
.Header_header__1VCKf {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    z-index: 2;
    direction: ltr;
}

.Button_button__3QF_k {
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #FFFFFF;
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
}

.Button_button__3QF_k:hover {
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.1);
}

.Button_extraSmall__22B-C {
    width: 46px;
    height: 40px;
}

.Button_small__1zbeU {
    width: 66px;
    height: 40px;
}

.Button_medium__36X0N {
    width: 96px;
    height: 54px;
    padding: 16px 20px;
    font-size: 15px;
    line-height: 22px;
}

.Button_large__3F305 {
    width: 112px;
    height: 54px;
    font-size: 15px;
    padding: 16px 20px;
    line-height: 22px;
    background: #4CC18B;
    border: none;
}
.Button_large__3F305:hover {
    background: #4CC18B;
}

.Button_fullwidth__38bph {
    width: 100%;
}
.Button_unsetWidth__3MEUw {
    width: unset;
}
.Button_unsetHeight__1UYAX {
    height: unset;
}

.Button_row__2oIFf {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Button_row__2oIFf.Button_ltr__6kSu2 {
    flex-direction: row;
}

.Button_row__2oIFf.Button_rtl__3mJ6n {
    flex-direction: row-reverse;
}

.Button_icon__23JL_ svg {
    margin-left: 8px;
    display: block;
}

.Button_left__8hZHh {
    border-radius: 4px 0 0 4px;
}
.Button_right__2vSSw {
    border-radius: 0 4px 4px 0;
}
.Button_all__2YEUz {
    border-radius: 4px 4px 4px 4px;
}

.Button_selected__1LYm5 {
    background: #FFFFFF;
    color: #0D5068;
    transition: all 0.2s ease-out;
}
.Button_selected__1LYm5:hover {
    background: #FFFFFF;
    color: #0D5068;
}
@keyframes LoadingSpinner_spinner__3AWWB {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.LoadingSpinner_spinner__3AWWB {
    width: 16px;
    height: 16px;
    border: 3px solid #ffffff;
    border-top-color: transparent;
    border-radius: 50%;
    animation: LoadingSpinner_spinner__3AWWB 1.5s linear infinite;
}

.LoadingSpinner_container__X0ORX {
    display: grid;
    justify-content: center;
    align-items: center;
}
.MainContent_content__1-gbi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    flex: 1 1;
    font-size: 16px;
    grid-gap: 16px;
    gap: 16px;
    text-align: center;
    position: relative;
}

.MainContent_subTitle__w-T68 {
    line-height: 1.2;
    font-size: 48px;
    width: 85%;
}

.MainContent_subTitle__w-T68.MainContent_rtl__1-7QO {
    font-size: 39px;
}

.MainContent_text__3wOGn {
    line-height: 1.5;
    width: 70%;
}

@media screen and (max-width: 1300px){
    .MainContent_subTitle__w-T68 {
        font-size: 30px;
        width: 75%;
    }
    .MainContent_subTitle__w-T68.MainContent_rtl__1-7QO {
        font-size: 30px;
    }
    .MainContent_text__3wOGn {
        width: 75%;
    }
    .MainContent_text__3wOGn.MainContent_rtl__1-7QO {
        font-size: 13px;
    }
}

@media screen and (max-width: 767px){
    .MainContent_subTitle__w-T68 {
        font-size: 28px;
        width: 100%;
    }
    .MainContent_subTitle__w-T68.MainContent_rtl__1-7QO {
        font-size: 23px;
    }
    .MainContent_text__3wOGn {
        width: 100%;
    }
    .MainContent_text__3wOGn.MainContent_rtl__1-7QO {
        font-size: 12px;
    }
}
.Buttons_buttons__3s2ne {
    margin-top: 8px;
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    min-height: 54px;
    justify-content: center;
}
.ZendeskSupport_wrapper__ikECq {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    color: #0D5068;
    cursor: pointer;
    padding: 0 4px;
}

.ZendeskSupport_wrapper__ikECq.ZendeskSupport_ltr__270VR {
    flex-direction: row;
}

.ZendeskSupport_wrapper__ikECq.ZendeskSupport_rtl__3ShGO {
    flex-direction: row-reverse;
}

.ZendeskSupport_zendesk__3Z1d_ {
    position: absolute;
    bottom: 22px;
}

.ZendeskSupport_zendesk__3Z1d_.ZendeskSupport_ltr__270VR  {
    right: 8px;
}

.ZendeskSupport_zendesk__3Z1d_.ZendeskSupport_rtl__3ShGO {
    left: 8px;
}

.ZendeskSupport_text__-34e3 {
    font-size: 14px;
}

@media screen and (max-width: 767px){
    .ZendeskSupport_text__-34e3 {
        display: none;
    }
}


.MainFooter_link__2jynX {
    font-size: 12px;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    line-height: 21px;
}

.MainFooter_link__2jynX:hover {
    opacity: 0.7;
}

.MainFooter_copyright__3kkb_ {
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    line-height: 21px;
}

.MainFooter_logo__1vhNm img{
    width: 105px;
    height: 32px;
}

.MainFooter_takamolLogo__1NDh9 img {
    width: 62px;
    height: 32px;
    margin: 0 8px;
}

.MainFooter_phoneBlock__2r7rw {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
}

.MainFooter_phoneLink__3PeG8 {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.MainFooter_phoneIcon__2cMuE img {
    width: 18px;
    height: 18px;
    margin: 3px 0 3px 3px;
}

.MainFooter_phoneIcon__2cMuE.MainFooter_ltr__eq0Z3 {
    order: 0;
}

.MainFooter_phoneIcon__2cMuE.MainFooter_rtl__2V8I7 {
    order: 1;
}

.MainFooter_phoneText__2pbTu {
    font-size: 12px;
    font-weight: 700;
    direction: ltr;
    margin: 0;
}

@media screen and (max-width: 1100px) {
    .MainFooter_copyright__3kkb_.MainFooter_rtl__2V8I7, .MainFooter_link__2jynX.MainFooter_rtl__2V8I7 {
        font-size: 11px;
    }
}
.Footer_footer__1w0lV{
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    align-items: center;
    -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 767px){
    .Footer_footer__1w0lV{
        grid-gap: 20px;
        gap: 20px;
        flex-direction: column;
    }
}
.Main_wrapper__119G9 {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
