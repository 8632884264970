.link {
    font-size: 12px;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    line-height: 21px;
}

.link:hover {
    opacity: 0.7;
}

.copyright {
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    line-height: 21px;
}

.logo img{
    width: 105px;
    height: 32px;
}

.takamolLogo img {
    width: 62px;
    height: 32px;
    margin: 0 8px;
}

.phoneBlock {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.phoneLink {
    display: flex;
    align-items: center;
    gap: 8px;
}

.phoneIcon img {
    width: 18px;
    height: 18px;
    margin: 3px 0 3px 3px;
}

.phoneIcon.ltr {
    order: 0;
}

.phoneIcon.rtl {
    order: 1;
}

.phoneText {
    font-size: 12px;
    font-weight: 700;
    direction: ltr;
    margin: 0;
}

@media screen and (max-width: 1100px) {
    .copyright.rtl, .link.rtl {
        font-size: 11px;
    }
}