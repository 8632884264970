.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    flex: 1;
    font-size: 16px;
    gap: 16px;
    text-align: center;
    position: relative;
}

.subTitle {
    line-height: 1.2;
    font-size: 48px;
    width: 85%;
}

.subTitle.rtl {
    font-size: 39px;
}

.text {
    line-height: 1.5;
    width: 70%;
}

@media screen and (max-width: 1300px){
    .subTitle {
        font-size: 30px;
        width: 75%;
    }
    .subTitle.rtl {
        font-size: 30px;
    }
    .text {
        width: 75%;
    }
    .text.rtl {
        font-size: 13px;
    }
}

@media screen and (max-width: 767px){
    .subTitle {
        font-size: 28px;
        width: 100%;
    }
    .subTitle.rtl {
        font-size: 23px;
    }
    .text {
        width: 100%;
    }
    .text.rtl {
        font-size: 12px;
    }
}