.footer{
    display: flex;
    gap: 16px;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    align-items: center;
    -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 767px){
    .footer{
        gap: 20px;
        flex-direction: column;
    }
}