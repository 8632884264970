@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.spinner {
    width: 16px;
    height: 16px;
    border: 3px solid #ffffff;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.container {
    display: grid;
    justify-content: center;
    align-items: center;
}