@import "./reset.css";

@font-face {
  font-family: 'LoewNext';
  src: url('./font/loew_next_arabic_medium.woff') format('woff'), url('./font/loew_next_arabic_medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'LoewNext';
  src: url('./font/loew_next_arabic_bold.woff') format('woff'), url('./font/loew_next_arabic_bold.woff2') format('woff2');
  font-weight: 700;
}

html, body, #root {
  min-height: 100vh;
  font-weight: 400;
}

.arial {
  & * {
    font-family: Arial, sans-serif;
  }
}

.loew {
  & * {
    font-family: LoewNext, sans-serif;
  }
}