.container {
    padding: 0 20px;
}

.ltr {
    direction: ltr;
}

.rtl {
    direction: rtl;
}

@media screen and (min-width: 575.98px){
    .container {
        max-width: 540px;
        margin: auto;
        padding: 0;
    }
}

@media screen and (min-width: 767.98px){
    .container {
        max-width: 720px;
        padding: 0 24px
    }
}

@media screen and (min-width: 991.98px) {
    .container {
        max-width: 960px;
    }
}

@media screen and (min-width: 1199.98px){
    .container {
        max-width: 1140px;

    }
}