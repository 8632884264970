.wrapper {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #0D5068;
    cursor: pointer;
    padding: 0 4px;
}

.wrapper.ltr {
    flex-direction: row;
}

.wrapper.rtl {
    flex-direction: row-reverse;
}

.zendesk {
    position: absolute;
    bottom: 22px;
}

.zendesk.ltr  {
    right: 8px;
}

.zendesk.rtl {
    left: 8px;
}

.text {
    font-size: 14px;
}

@media screen and (max-width: 767px){
    .text {
        display: none;
    }
}

