.app {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}

.app::before {
  content: '';
  background: url("../assets/images/bottomElipse.svg") no-repeat;
  background-size: 100% 100%;
  width: 355px;
  height: 355px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.app::after {
  content: '';
  background: url("../assets/images/1280.svg") no-repeat;
  background-size: 100% 100%;
  width: 638px;
  height: 542px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

@media screen and (max-width: 1100px){
  .app::before {
    left: -140px;
  }
  .app::after{
    background: url("../assets/images/768.svg") no-repeat;
    background-size: 100% 100%;
    width: 302px;
    height: 542px;
  }
}

@media screen and (max-width: 767px) {
  .app::before {
    width: 227px;
    height: 227px;
    left: -146px;
  }
  .app::after{
    background: url("../assets/images/375.svg") no-repeat;
    background-size: 100% 100%;
    width: 228px;
    height: 418px;
  }
}

@media screen and (max-width: 374px){
  .app::after{
    right: -90px;
  }
}
